/*-------------------------------------------*\
    Tabs
\*-------------------------------------------*/

:root {
    --tab-bg: linear-gradient(180deg, rgba(252, 252, 254, 1) 0%, rgba(244, 243, 238, 1) 100%);
    --tab-border: 1px solid #919b9c;
}

menu[role="tablist"] {
    button {
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(250, 250, 249, 1) 26%,
            rgba(240, 240, 234, 1) 95%,
            rgba(236, 235, 229, 1) 100%
        );
        margin-left: -1px;
        margin-right: 2px;
        border-radius: 0;
        border-color: #91a7b4;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        padding: 0 12px 3px;
        &:hover {
            box-shadow: unset;
            border-top: 1px solid #e68b2c;
            box-shadow: inset 0px 2px #ffc73c;
        }
        &[aria-selected="true"] {
            background: #fcfcfe;
            border-color: #919b9c;
            margin-right: -1px;
            border-bottom: 1px solid transparent;
            border-top: 1px solid #e68b2c;
            box-shadow: inset 0px 2px #ffc73c;
            &:first-of-type {
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    z-index: -1;
                    top: 100%;
                    left: -1px;
                    height: 2px;
                    width: 0;
                    border-left: var(--tab-border);
                }
            }
        }
    }
}
[role="tabpanel"] {
    box-shadow: inset 1px 1px #fcfcfe, inset -1px -1px #fcfcfe, 1px 2px 2px 0px rgba(208, 206, 191, 0.75);
}
