/*-------------------------------------------*\
    Tabs
\*-------------------------------------------*/

:root {
    --tab-border: 1px solid #888;
    --tab-bg: var(--surface);
}

[role="tabpanel"] {
    position: relative;
    z-index: 2;
}

menu[role="tablist"] {
    position: relative;
    margin: 0 0 -2px 0;
    text-indent: 0;
    list-style-type: none;
    display: flex;
    padding-left: 3px;
    button {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        z-index: 1;
        display: block;
        color: #222;
        text-decoration: none;
        min-width: unset;
        &[aria-selected="true"] {
            padding-bottom: 2px;
            margin-top: -2px;
            background-color: var(--surface);
            position: relative;
            z-index: 8;
            margin-left: -3px;
            margin-bottom: 1px;
        }
        &:focus {
            outline: 1px dotted #222;
            outline-offset: -4px;
        }
    }
    &.justified {
        button {
            flex-grow: 1;
            text-align: center;
        }
    }
}

[role="tabpanel"] {
    padding: 14px;
    clear: both;
    background: var(--tab-bg);
    border: var(--tab-border);
    position: relative;
    z-index: 2;
    margin-bottom: 9px;
}
