/*-------------------------------------------*\
    GroupBox
\*-------------------------------------------*/

fieldset {
    border-image: svg-load("./icon/groupbox-border.svg") 2;
    padding: calc(2 * var(--border-width) + var(--element-spacing));
    padding-block-start: var(--element-spacing);
    margin: 0;
}
legend {
    background: var(--surface);
}
.field-row {
    display: flex;
    align-items: center;
    > * {
        + {
            * {
                margin-left: var(--grouped-element-spacing);
            }
        }
    }
}
[class^="field-row"] {
    + {
        [class^="field-row"] {
            margin-top: var(--grouped-element-spacing);
        }
    }
}
.field-row-stacked {
    display: flex;
    flex-direction: column;
    * {
        + {
            * {
                margin-top: var(--grouped-element-spacing);
            }
        }
    }
}
