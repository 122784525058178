/*-------------------------------------------*\
    Fonts
\*-------------------------------------------*/

@font-face {
    font-family: "Pixelated MS Sans Serif";
    src: url("fonts/converted/ms_sans_serif.woff") format("woff");
    src: url("fonts/converted/ms_sans_serif.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Pixelated MS Sans Serif";
    src: url("fonts/converted/ms_sans_serif_bold.woff") format("woff");
    src: url("fonts/converted/ms_sans_serif_bold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Perfect DOS VGA 437 Win";
    src: url("fonts/converted/PerfectDOSVGA437Win.woff2") format("woff2"),
        url("fonts/converted/PerfectDOSVGA437Win.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
